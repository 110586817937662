


import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PDF from "../../../static/pdf/resources/Holy_Friday_Vesp_-_Come_Let_Us__Bless_Joseph_Text_&_Icon.pdf"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/resources.css"


function Resources () {
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/resources/*.png" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid_noBase64
	                }
	              }
	            }
	          }
	        }
			}
	`


	const images = useStaticQuery(query).allFile
	const image = images.edges.find(element => element.node.name === 'bless-joseph').node

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content resources">
				<div className="services-image-wrapper">
					<Img 		           		
						fluid={image.childImageSharp.fluid}
	 					className="services-image" alt="Logo" 
	 				/>

				</div>
				<h2>Holy Saturday - Bless Joseph</h2>
				<p></p>
				
								<div className="recording-iframe-wrapper first-recording">
									<iframe title="Bless Joseph" height="122" width="100%"  scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/media/player/xwysz-d98c16?from=yiiadmin&download=1&version=1&skin=1&btn-skin=107&auto=0&download=1&pbad=1"></iframe>
								</div>


					<div className='link-button'>	
							<a href={PDF} target="_blank">Download Hymn Text</a>
					</div>


			</main>
			<Footer />		
		</div>
		)
}

export default Resources 
